import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, Subscription, tap } from 'rxjs';

import { AddCountersignersParams } from '@app/route/fund/fund-lps/countersigning-modal';
import { DataTableRowString } from '@component/data-table';
import { environment } from '@env/environment';
import { ID } from '@state/base';
import { Investor, InvestorRepository } from '@state/investor';
import { MessageParams } from '@state/fund-messages';
import { OverviewCard } from '@component/overview-cards';
import {
  EntityService,
  FeatureConfigurationParams,
  FeatureConfigurationUserPermissions,
  FeatureRoleConfigurationActionType
} from '@state/entity';

import {
  BlueSky,
  CSVExportResponse,
  CustomValue,
  Details,
  Fund,
  FundBankAccount,
  Investor as FundInvestor,
  FundNotification,
  InvestorQuestionnaireReview,
  InvestorQuestionnaireData,
  InviteRequestOptions,
  LPProfileData,
  LegalData,
  LegalRow,
  LegalSection,
  LegalStatus,
  OwnershipOption,
  OwnershipType,
  Summary,
  InvestorQuestionnaireReviewActivityLog,
  QuestionnaireReviewMessage
} from './fund.model';
import { FundRepository } from './fund.repository';

// NOTE: Initially I thought using seperate stores for each fund
// attribute / chunk of data would be prudent, but after more
// consideration, I'm starting to think that may actually make
// it more confusing to determine where to seek it.
// I believe our best bet will be to have each function that writes data
// in the service check the ID, and in situations where it doesn't match
// we should clear out all data from the store before setting it like new again
// In theory, since all the child routes related to funds transition through
// the parent, the parent route / getOverview function could handle this logic.
// If it becomes too complicated we may want to revisit the idea of putting
// seperate endpoint payloads related to funds into seperate akita stores

// PS: I actually think it may still be worth creating seperate entity stores
// For both fund-lps and fund-documents, since these are both actually collections

export interface RejectInvestorLegalData {
  investment_ids: ID[];
  message_params?: MessageParams;
  vehicle_id: ID;
}

@Injectable({ providedIn: 'root' })
export class FundService {
  constructor(
    private entityService: EntityService,
    private fundRepository: FundRepository,
    private http: HttpClient,
    private investorRepository: InvestorRepository
  ) {}

  addCounterSigner$(vehicle_id: ID, params: AddCountersignersParams): Observable<Details> {
    return this.http.post<Details>(
      `${environment.apiUrl}/v1/vehicles/${vehicle_id}/add_countersigner`,
      params
    );
  }

  bulkApproveInvestorLegalData$(investment_ids: ID[], vehicleId: ID, sectionId: ID): Observable<LegalRow> {
    let url = `${environment.apiUrl}/v1/investments/approve_signature_requests`;
    return this.http.patch<LegalRow>(url, { investment_ids }).pipe(
      tap({
        next: () => {
          this.getAndStoreSectionInfo$(vehicleId, sectionId).subscribe();
        }
      })
    );
  }

  bulkDataRequest$({ filename, fundId }: { filename: string; fundId: ID }): Observable<null> {
    let url = `${environment.apiUrl}/v1/vehicles/${fundId}/reports/bulk_data_request`;
    return this.http.post<null>(url, { filename });
  }

  bulkRejectInvestorLegalData$(params: RejectInvestorLegalData, sectionId: ID): Observable<LegalRow> {
    let url = `${environment.apiUrl}/v1/investments/reject_signature_requests`;
    let { vehicle_id: vehicleId } = params;

    return this.http.patch<LegalRow>(url, params).pipe(
      tap({
        next: () => {
          this.getAndStoreSectionInfo$(vehicleId, sectionId).subscribe();
        }
      })
    );
  }

  createQuestionnaireReview$({
    actionType,
    email,
    investmentId,
    message,
    vehicleId
  }: {
    actionType: FeatureRoleConfigurationActionType;
    email: QuestionnaireReviewMessage;
    investmentId: ID;
    message: string;
    vehicleId: ID;
  }): Observable<null> {
    let url = `${environment.apiUrl}/v1/vehicles/${vehicleId}/investments/${investmentId}/questionnaire/review`;

    let body = {
      email,
      message,
      questionnaire_review_action: actionType
    };

    return this.http.post<null>(url, body);
  }

  exportCSV$({ filename, fundId }: { filename: string; fundId: ID }): Observable<CSVExportResponse> {
    let url = `${this.makeUrl(fundId)}/investors/export`;
    return this.http.post<CSVExportResponse>(url, { filename });
  }

  get$(id: ID, providerId?: ID): Observable<Fund> {
    let url = this.makeUrl(id);
    let options = {};

    if (providerId) {
      let headers = new HttpHeaders({ 'Provider-Id': providerId as string });
      options = { headers };
    }

    return this.http.get<Fund>(url, options);
  }

  getAndStoreBankAccount$(entity_id: ID): Observable<FundBankAccount> {
    let url = `${environment.apiUrl}/v1/entities/${entity_id}/bank_account`;
    return this.http.get<FundBankAccount>(url).pipe(
      tap({
        next: (bankAccount) => {
          this.fundRepository.setBankAccount(bankAccount);
        }
      })
    );
  }

  getAndStoreDetails$(id: ID): Observable<Details> {
    let url = this.makeUrl(id, '/fund');

    return this.http.get(url).pipe(
      tap({
        next: (details: Details) => {
          this.fundRepository.setDetails(details);
        }
      })
    );
  }

  getAndStoreFund$(id: ID, providerId?: ID): Observable<Fund> {
    return this.get$(id, providerId).pipe(
      tap({
        next: (fund: Fund) => {
          this.fundRepository.setFund(fund);
        }
      })
    );
  }

  getAndStoreInvestorQuestionnaireData$(
    vehicleId: ID,
    investmentId: ID
  ): Observable<InvestorQuestionnaireData[]> {
    let url = `${environment.apiUrl}/v1/vehicles/${vehicleId}/investments/${investmentId}/questionnaire`;

    return this.http.get<InvestorQuestionnaireData[]>(url).pipe(
      tap({
        next: (investorQuestionnaireData) => {
          this.fundRepository.setInvestorQuestionnaireData(investorQuestionnaireData);
        }
      })
    );
  }

  getAndStoreInvestorQuestionnaireReview$(
    vehicleId: ID,
    investmentId: ID
  ): Observable<InvestorQuestionnaireReview> {
    let url = `${environment.apiUrl}/v1/vehicles/${vehicleId}/investments/${investmentId}/questionnaire/review`;

    return this.http.get<InvestorQuestionnaireReview>(url).pipe(
      tap({
        next: (investorQuestionnaireReview) => {
          this.fundRepository.setInvestorQuestionnaireReview(investorQuestionnaireReview);
        }
      })
    );
  }

  getAndStoreinvestorQuestionnaireReviewActivityLog$(
    vehicleId: ID,
    investmentId: ID
  ): Observable<InvestorQuestionnaireReviewActivityLog> {
    let url = `${environment.apiUrl}/v1/vehicles/${vehicleId}/investments/${investmentId}/questionnaire/review/activity_log`;

    return this.http.get<InvestorQuestionnaireReviewActivityLog>(url).pipe(
      tap({
        next: (investorQuestionnaireReviewActivityLog) => {
          this.fundRepository.setinvestorQuestionnaireReviewActivityLog(
            investorQuestionnaireReviewActivityLog
          );
        }
      })
    );
  }

  getAndStoreInvestors$(id: ID, provider_entity_id?: ID): Observable<FundInvestor[]> {
    let url = this.makeUrl(id, '/investors');
    let options = {};

    if (provider_entity_id) {
      let headers = new HttpHeaders({ 'Provider-Id': provider_entity_id as string });
      options = { headers };
    }

    return this.http.get<FundInvestor[]>(url, options).pipe(
      tap({
        next: (investors) => {
          this.fundRepository.setInvestors(investors);
        }
      })
    );
  }

  getAndStoreFeatureConfigurationUserPermissions$({
    entity_id,
    type
  }: FeatureConfigurationParams): Observable<FeatureConfigurationUserPermissions> {
    return this.entityService.getFeatureConfiguration$({ entity_id, type }).pipe(
      tap({
        next: (featureConfigurationUserPermissions) => {
          this.fundRepository.setFeatureConfigurationUserPermissions(
            featureConfigurationUserPermissions,
            type
          );
        }
      })
    );
  }

  getAndStoreNotifications$(id: ID): Observable<FundNotification[]> {
    let url = this.makeUrl(id, '/notification_config');

    return this.http.get<FundNotification[]>(url).pipe(
      tap({
        next: (notifications) => {
          this.fundRepository.setNotifications(notifications);
        }
      })
    );
  }

  getAndStoreOverviewInvestments$(id: ID, provider_entity_id?: ID): Observable<OverviewCard[][]> {
    let url = this.makeUrl(id, '/overview_investments');
    let options = {};

    if (provider_entity_id) {
      let headers = new HttpHeaders({ 'Provider-Id': provider_entity_id as string });
      options = { headers };
    }

    return this.http.get<OverviewCard[][]>(url, options).pipe(
      tap({
        next: (overviewInvestments) => {
          this.fundRepository.setOverviewInvestments(overviewInvestments);
        }
      })
    );
  }

  getAndStoreSectionInfo$(vehicleId: ID, sectionId: ID, params?: HttpParams): Observable<LegalData> {
    let url = `${environment.apiUrl}/v1/vehicles/${vehicleId}/reports/legal_data?section_id=${sectionId}`;
    return this.http.get<LegalData>(url, { params }).pipe(
      tap({
        next: (legalData) => {
          this.fundRepository.setLegalData(legalData);
        }
      })
    );
  }

  getAndStoreSections$(vehicleId: ID): Observable<LegalSection[]> {
    let url = `${environment.apiUrl}/v1/vehicles/${vehicleId}/reports/legal_data/form_sections`;
    return this.http.get<LegalSection[]>(url).pipe(
      tap({
        next: (legalSections) => {
          this.fundRepository.setLegalSections(legalSections);
        }
      })
    );
  }

  getAndStoreSummary$(id: ID, provider_entity_id?: ID): Observable<Summary> {
    let url = this.makeUrl(id, '/summary');
    let options = {};

    if (provider_entity_id) {
      let headers = new HttpHeaders({ 'Provider-Id': provider_entity_id as string });
      options = { headers };
    }

    return this.http.get<Summary>(url, options).pipe(
      tap({
        next: (summary) => {
          this.fundRepository.setSummary(summary);
        }
      })
    );
  }

  getBlueSky$(id: ID): Observable<BlueSky> {
    let url = this.makeUrl(id, '/reports/blue_sky');
    return this.http.get(url) as Observable<BlueSky>;
  }

  getDetails$(id: ID): Observable<Details> {
    let url = this.makeUrl(id, '/fund');
    return this.http.get<Details>(url);
  }

  getFund(id: ID): any {
    return this.getAndStoreFund$(id).subscribe();
  }

  getHoldingsCSV$(vehicleId: ID): Observable<Blob> {
    let url = `${environment.apiUrl}/v1/holding_valuation_update_template?vehicle_id=${vehicleId}`;
    return this.http.get(url, { responseType: 'blob' });
  }

  getInvestors(id: ID): Subscription {
    return this.getAndStoreInvestors$(id).subscribe();
  }

  getLPProfileData$(id: ID): Observable<LPProfileData> {
    let url = this.makeUrl(id, '/reports/profile_data');
    return this.http.get<LPProfileData>(url);
  }

  getOverviewInvestments(id: ID): Subscription {
    return this.getAndStoreOverviewInvestments$(id).subscribe();
  }

  getOwnershipOptions$(vehicleId: ID, ownershipType: OwnershipType): Observable<OwnershipOption[]> {
    let url = this.makeUrl(vehicleId, `/ownership_options?ownership_type=${ownershipType}`);
    return this.http.get(url) as Observable<OwnershipOption[]>;
  }

  getSummary(id: ID): Subscription {
    return this.getAndStoreSummary$(id).subscribe();
  }

  getUnobfuscatedCustomValue$(investment_id: ID, custom_variable_key: string): Observable<CustomValue> {
    let url = `${environment.apiUrl}/v1/investments/${investment_id}/unobfuscated_value`;
    let params = new HttpParams().set('custom_variable_key', custom_variable_key);

    return this.http.get<CustomValue>(url, { params });
  }

  inviteInvestors$({
    vehicle_id,
    inviteRequestOptions
  }: {
    vehicle_id: ID | string;
    inviteRequestOptions: InviteRequestOptions;
  }): Observable<object> {
    let url = `${environment.apiUrl}/offerings/${vehicle_id}/create_invitation`;
    let params = { forms_attached: false, ...inviteRequestOptions };
    return this.http.post(url, params);
  }

  makeUrl(id: ID, path?: string): string {
    return `${environment.apiUrl}/v1/vehicles/${id}${path || ''}`;
  }

  reset(): void {
    this.fundRepository.reset();
  }

  resetSubscription$(
    vehicle_id: ID,
    investment_id: ID,
    { send_email, message, subject }: { send_email: boolean; message?: string; subject?: string }
  ): Observable<null> {
    let urlPrefix = `${environment.apiUrl}/v1/vehicles`;
    let url = `${urlPrefix}/${vehicle_id}/investors/${investment_id}/reset_subscription_task`;
    return this.http.post<null>(url, { message, send_email, subject });
  }

  selectQuestionnaireLegalSection$(vehicleId: ID, section: LegalSection): Observable<LegalData> {
    this.fundRepository.setSelectedLegalSection(section);
    return this.getAndStoreSectionInfo$(vehicleId, section.id);
  }

  toggleNotificationSubscription$(notification: FundNotification): Observable<void> {
    let { id } = this.fundRepository.getFund();
    let url = this.makeUrl(id, '/notification_config');
    let body = { notification_config: { action: notification.action } };

    let request$ = notification.subscribed
      ? this.http.post<void>(url, body)
      : this.http.delete<void>(url, { body });

    return request$.pipe(
      tap({
        next: () => {
          let notifications = this.fundRepository.getNotifications();
          notifications.filter((storeNotification) => storeNotification.action === notification.action)[0] =
            notification;

          this.fundRepository.setNotifications(notifications);
        }
      }),
      catchError((err) => {
        notification.subscribed = !notification.subscribed;
        throw err;
      })
    );
  }

  toggleObfuscatedDataRowValue = (
    investmentId: ID,
    customVariableKey: string,
    obfuscatedValue: string,
    row: DataTableRowString
  ): void => {
    let rowMatchesOriginalValue = row.value === obfuscatedValue;

    if (rowMatchesOriginalValue) {
      this.getUnobfuscatedCustomValue$(investmentId, customVariableKey).subscribe({
        next: (response) => {
          row.value = response[customVariableKey];
          row.obfuscated = false;
        }
      });
    } else {
      row.value = obfuscatedValue;
      row.obfuscated = true;
    }
  };

  updateInvestor(investor: Investor): any {
    this.investorRepository.setInvestor(investor);
    this.fundRepository.setFundInvestorFromInvestor(investor);
  }

  updateTags(activeLegalStatusTags: LegalStatus[]): void {
    this.fundRepository.setActiveLegalStatusTags(activeLegalStatusTags);
  }
}
